import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import OpenTable, { positions } from "react-opentable"

import { SEO, Layout, PageHeader, MainWrapper } from "components"
import background from "../images/back-texture.png"

const HowBack = styled.section`
  background: var(--whiteChalk);
  position: relative;
  background-image: ${({ background }) =>
    `linear-gradient(
            rgba(255, 255, 255, 0.95),
            hsl(0deg 0% 100%)
          ),url(${background})` || "var(--black)"};
`

const RevWrap = styled.div`
  padding: 170px 30px;
`

const Reservation = styled.div`
  display: flex;
  justify-content: center;
`

const ReservationPage = () => (
  <Layout>
    <SEO title="Reservations" />
    <PageHeader title="Reservations">
      <StaticImage
        src="../images/slider/3.jpg"
        placeholder="blurred"
        alt="Menu"
        quality="100"
      />
    </PageHeader>
    <HowBack background={background}>
      <MainWrapper>
        <RevWrap
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
        >
          <Reservation>
            <OpenTable
              rid="1182076"
              customClassName="custom-ot-wrapper"
              position={positions.POSITION_UNSET}
            />
          </Reservation>
        </RevWrap>
      </MainWrapper>
    </HowBack>
  </Layout>
)

export default ReservationPage
